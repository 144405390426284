import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlSelectDeviceAttributeModel, FormControlModel } from '@common-src/model/form-control';
import { GroupQueryModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import FacilityConfigTypeService from '@common-src/service/facility-config-type';
import FacilityConfigGroupService from '@common-src/service/facility-config-group';
import { FacilityBindType, FacilityLinkType, FormControlSelectDeviceAttributeType } from '@common-src/model/enum';
import { NUMBER_LETTER_UNDERLINE_REGEXP } from '@common-src/model/regexp';
import { getDeviceIdAndAttributeKey } from './device-entity';
import LabelService from '@common-src/service/label';

export class FacilityConfigEntityModel extends BaseEntityModel {
    constructor(groupId?: string) {
        super();
        this.groupId = groupId;
    }

    @FormControl({
        label: '分组',
        type: FormControlType.TREE_SELECT,
        optionsPromise: FacilityConfigGroupService.getGroupTree,
        required: false
    } as FormControlOptionModel)
    groupId: string = undefined;

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '类型',
        type: FormControlType.SELECT,
        required: true,
        optionsPromise: FacilityConfigTypeService.getAllFacilityConfigType,
        cascad: true,
        readonly: true,
        message: '请选取已配置类型'
    } as FormControlOptionModel)
    typeId: string = undefined;
    typeName: string = undefined;

    @FormControl({
        label: '关联设备',
        type: FormControlType.SELECT,
        optionsPromise: FacilityConfigTypeService.getDeviceListByTypeId,
        optionsPromiseParam: 'typeId',
        mode: 'multiple',
        invisibleFunction: model => !(model.bindType === FacilityBindType.STATS && model.linkType === FacilityLinkType.DEVICE),
        required: true
    } as FormControlOptionModel)
    linkDeviceList: Array<string> = undefined;

    @FormControl({
        label: '关联设备属性',
        type: FormControlType.SELECT_DEVICE_ATTRIBUTE,
        mode: 'multiple',
        selectType: FormControlSelectDeviceAttributeType.FACILITY_CONFIG,
        scopeId: 'typeId',
        invisibleFunction: model => !(model.bindType === FacilityBindType.STATS && model.linkType === FacilityLinkType.DEVICE_ATTR),
        required: true
    } as FormControlSelectDeviceAttributeModel)
    linkDeviceAttributeList: Array<string> = undefined;

    @FormControl({
        label: '空间位置',
        type: FormControlType.SELECT_SPACE,
        required: false
    } as FormControlModel<string>)
    locationId: string = undefined;
    locationName: string = undefined;

    @FormControl({
        label: '标签',
        type: FormControlType.SELECT,
        optionsPromise: LabelService.getAllLabel.bind(LabelService),
        mode: 'multiple',
        required: false
    } as FormControlOptionModel)
    tagIds: string = undefined;

    bindType: FacilityBindType = undefined;
    linkType: FacilityLinkType = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '类型',
                dataIndex: 'typeName'
            },
            {
                title: '位置',
                dataIndex: 'locationName'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        switch (this.linkType) {
            case FacilityLinkType.DEVICE:
                this.linkDeviceList = _.map(_.get(json, 'relations'), item => item.entityId);
                break;
            case FacilityLinkType.DEVICE_ATTR:
                this.linkDeviceAttributeList = _.map(_.get(json, 'relations'), item => `${item.entityId}|${item.attrKey}`);
                break;
        }
        return this;
    }

    toService() {
        const data = {
            id: this.id,
            identifier: this.identifier,
            typeId: this.typeId,
            name: this.name,
            groupId: this.groupId,
            tagIds: this.tagIds,
            locationId: this.locationId
        } as any;
        if (this.bindType === FacilityBindType.STATS) {
            switch (this.linkType) {
                case FacilityLinkType.DEVICE:
                    data.relations = _.map(this.linkDeviceList, item => {
                        return { entityId: item };
                    });
                    break;
                case FacilityLinkType.DEVICE_ATTR:
                    data.relations = _.map(this.linkDeviceAttributeList, item => {
                        const { deviceId, attributeKey } = getDeviceIdAndAttributeKey(item);
                        return {
                            entityId: deviceId,
                            attrKey: attributeKey
                        };
                    });
                    break;
            }
        }
        return data;
    }
}

export class FacilityConfigQueryModel extends GroupQueryModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 12
    })
    name: string = undefined;

    toService() {
        return {
            params: {
                name: this.name,
                groupIds: this.GroupIds
            }
        };
    }
}
