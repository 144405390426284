
import { FacilityConfigEntityModel, FacilityConfigQueryModel } from '@common-src/entity-model/facility-config-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/stats`;

class FacilityConfigService implements ICRUDQ<FacilityConfigEntityModel, FacilityConfigQueryModel> {
    async create(model: FacilityConfigEntityModel):Promise<FacilityConfigEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<FacilityConfigEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new FacilityConfigEntityModel().toModel(res);
    }

    async update(model: FacilityConfigEntityModel):Promise<FacilityConfigEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: FacilityConfigEntityModel):Promise<FacilityConfigEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: FacilityConfigQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new FacilityConfigEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new FacilityConfigEntityModel().toModel(item));
    }
}

export default new FacilityConfigService();
